export const Introduction = () => {
    return(
        <section className="about" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div>
                            <h2>About me</h2>
                            <p className="mt-4">
                                I have an outgoing personality, eager to learn new things due to the fact that 
                                I cannot stand not knowing how something works. 
                                I have great communication skills, I'm always on time, hard-working and work well in teams.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}