import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useState } from "react";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

import CanvasLoader from '../components/Loader';

const PythonLogo = (scaleLogo) => {

    const reactLogo = useGLTF('./pythonLogo/scene.gltf');
    
    return(
        <mesh>
            <hemisphereLight intensity={1} groundColor='black' />
            <spotLight
                position={[-20,50,10]}
            />
            <pointLight intensity={1}/>
            <primitive
                object={reactLogo.scene}
                rotation={[0,0.5,0]}
                scale={1}
            />
        </mesh>
    )
}

export const PythonLogoCanvas = (scaleLogo) => {


    return (
        <Canvas
        frameloop="demand"
        shadows
        camera={{position: [0,10,0], fov: 10}}
        gl={{preserveDrawingBuffer: true}}
        >
            <Suspense fallback={<CanvasLoader/>}>
                <OrbitControls 
                    enableZoom={false}
                    maxPolarAngle={Math.PI / 2}
                    minPolarAngle={Math.PI / 2}
                />
                <PythonLogo/>
            </Suspense>

            <Preload all/>
        </Canvas>
        
    )
}