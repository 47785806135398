import React from "../assets/img/meter1.svg";
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import { ReactLogoCanvas } from "../canvas/ReactLogo";
import { HtmlLogoCanvas } from "../canvas/HtmlLogo";
import { PythonLogoCanvas } from "../canvas/PythonLogo";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";

export const HardSkills = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const hardSkills = [
    { xs: 12, md: 4, lg: 3, xl: 2, mt: isSmallScreen ? "" : "", component: <ReactLogoCanvas />, text: "React" },
    { xs: 12, md: 4, lg: 3, xl: 2, mt: isSmallScreen ? "mt-4" : "", component: <HtmlLogoCanvas />, text: "HTML" },
    { xs: 12, md: 4, lg: 3, xl: 2, mt: isSmallScreen ? "mt-4" : "", component: <PythonLogoCanvas />, text: "Python" },
    ];

    return (
        <section className="skill" id="hardskills">     
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn">
                            <h2>Hard Skills</h2>
                            <Container>
                                <Row className="aligh-items-center">
                                {hardSkills.map((col, index) => (
                                    <Col key={index} xs={col.xs} md={col.md} lg={col.lg} xl={col.xl} className={col.mt}>
                                        <div>
                                        {col.component}
                                        {col.text}
                                        </div>
                                    </Col>  
                                ))}
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        <img className="background-image-left" src={colorSharp} alt="Image"/>
    </section>
    )
}