import './App.css';
import { NavBar } from './components/NavBar';
import { Banner } from "./components/Banner";
import { HardSkills } from "./components/HardSkills";
import { Projects } from './components/Projects';
import { Footer } from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Introduction } from './components/Introduction';
import { SoftSkills } from './components/SoftSkills';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Banner/>
      <Introduction/>
      <HardSkills/>
      <SoftSkills/>
      <Projects/>
      <Footer/>
    </div>
  );
}

export default App;
