import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import Ultimate_Board from "../assets/img/Ultimate_Tic-Tac-Toe_Board.png";
import BitCoin from "../assets/img/Bitcoin-Chart.jpg";
import Cosmos from "../assets/img/universo.jpg";
import MedicalSO from "../assets/img/MedicalSO.jpg";
import Marine from "../assets/img/marine.jpg";
import Ls from "../assets/img/ls.png";
import NN from "../assets/img/neural_networks.png";
import Rental4You from "../assets/img/Rental4You.png";
import DiscordBot from "../assets/img/discord_bot.jpg";
import ScreenShot from "../assets/img/screenshot.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projectsUni = [
    {
      title: "Ultimate Tic-Tac-Toe",
      description: "CLI board game that applies linked lists and memory allocations in C",
      imgUrl: Ultimate_Board,
      link: "https://github.com/Paulitchos/Trabalho_P",
    },
    {
      title: "Bitcoin Guesser",
      description: "Regression and DeepLearning Networks",
      imgUrl: BitCoin,
      link:"https://github.com/Paulitchos/IC-Project"
    },
    {
      title: "Cosmos Database",
      description: "Theoretical database that would serve to keep all the cosmos known in a database",
      imgUrl: Cosmos,
      link:"https://github.com/Paulitchos/Base_de_Dados",
    },
    {
      title: "MedicalSO",
      description: "Threads, Pipes and Signals for a system to manage coming Clients",
      imgUrl: MedicalSO,
      link:"https://github.com/Paulitchos/Unix_Project-SO",
    },
    {
      title: "Marine Port",
      description: "Database with functions,procedures and triggers that would manage a marine port",
      imgUrl: Marine,
      link:"https://github.com/Paulitchos/PortoMarinho"
    },
    {
      title: "Cross Words",
      description: "A basic find the words in the middle of an array of random letters",
      imgUrl: Ls,
      link:"https://crossword-ls.pages.dev/"
    },
    {
      title: "Shape Recognision",
      description: "Created neural networks used to identify geometrical shapes",
      imgUrl: NN,
      link:"https://github.com/Paulitchos/ShapeClassification-CR"
    },
    {
      title: "Rental4You",
      description: "Website made with Asp.NET for renting cars",
      imgUrl: Rental4You,
      link:"https://github.com/Paulitchos/Rental4You"
    },
  ];

  const projectsPersonal = [
    {
      title: "Discord Bot",
      description: "Music bot for discord (outdated)",
      imgUrl: DiscordBot,
      link: "https://github.com/Paulitchos/Chubbie_bot",
    },
  ];

  const projectsWork = [
    {
      title: "Automatic Screenshot",
      description: "Takes screenshots of website homepages, and saves it on google drive",
      imgUrl: ScreenShot,
      link: "https://github.com/Paulitchos/Data4You_Screenshot",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>These Projects can be found in my github page.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">University Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Personal Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Work Projects</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                            projectsUni.map((project, index) => {
                              return (
                                
                                <ProjectCard
                                  key={index}
                                  {...project}
                                  />
                                
                              )
                            })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                      {
                          projectsPersonal.map((project, index) => {
                            return (
                              
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                              
                            )
                          })
                      }
                    </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                      {
                          projectsWork.map((project, index) => {
                            return (
                              
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                              
                            )
                          })
                      }
                    </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}