import { Col } from "react-bootstrap";
import Github from "../assets/img/nav-icon2.svg"
export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
      <img src={imgUrl}/>
      
      <div className="proj-txtx">
        <div className="projects-icon">
          <a href={link}><img src={Github}></img></a>
        </div>
        <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  )
}