import React from "../assets/img/meter1.svg";
import Html from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { ReactLogoCanvas } from "../canvas/ReactLogo";
import { HtmlLogoCanvas } from "../canvas/HtmlLogo";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";

export const SoftSkills = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const softSkills = [
        {
            text: "Time management",
        },
        {
            text: "Problem-Solving Skills"
        },
        {
            text: "Communication"
        },
        {
            text: "Self-learning"
        },
        {
            text: "Self-awareness"
        }
      ];

    return (
        <section className="skill" id="softskills">     
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn mt-5">
                            <h2>Soft Skills</h2>
                            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider mt-3">
                            {
                                softSkills.map((softskill, index) => {
                                    return ( 
                                        <div className="item">
                                            <h7>{softskill.text}</h7>
                                        </div>
                                      )
                                    })
                            }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        <img className="background-image-left" src={colorSharp} alt="Image"/>
    </section>
    )
}